import React from "react"
import { Drawer, Button, Typography, Divider } from "antd"

import { IPaintMenuMethods } from "../../Pages/Paint/models"

import ColorPicker from "../ColorPicker"
import Dropdown from "../DropDown"
import { IPaintMenu } from "./model"

const PaintMenu = (props: IPaintMenu) => {
  const tools = [
    { value: "Pencil", display: "Pencil" },
    { value: "Select", display: "Select" },
    { value: "Circle", display: "Circle" },
    { value: "Rectangle", display: "Rectangle" },
    { value: "Line", display: "Line" },
    { value: "Pan", display: "Pan" },
  ]

  const fontFamilies = [
    { value: "Balsamiq Sans", display: "Balsamiq Sans" },
    { value: "Comfortaa", display: "Comfortaa" },
    { value: "Roboto", display: "Roboto" },
    { value: "Open Sans", display: "Open Sans" },
    { value: "Sriracha", display: "Sriracha" },
    { value: "monospace", display: "monospace" },
  ]

  const fontSizes = [
    { value: 8, display: "8" },
    { value: 10, display: "10" },
    { value: 20, display: "20" },
    { value: 28, display: "28" },
    { value: 36, display: "36" },
    { value: 72, display: "72" },
  ]

  const strokeWidths = [
    { value: 1, display: "1" },
    { value: 2, display: "2" },
    { value: 4, display: "4" },
    { value: 6, display: "6" },
    { value: 8, display: "8" },
    { value: 10, display: "10" },
    { value: 12, display: "12" },
    { value: 14, display: "14" },
    { value: 16, display: "16" },
    { value: 20, display: "20" },
  ]

  const [showTextColorPicker, toggleShowTextColorPicker] = React.useState(false)
  const [showFillColorPicker, toggleShowFillColorPicker] = React.useState(false)
  const [showStrokeColorPicker, toggleShowStrokeColorPicker] = React.useState(
    false
  )
  const [textColor, setTextColor] = React.useState("orangered")
  const [fontFamily, setFontFamily] = React.useState("Balsamiq Sans")
  const [fontSize, setFontSize] = React.useState(8)
  const [strokeWidth, setStrokeWidth] = React.useState(8)

  return (
    <Drawer
      title="Paint Menu"
      placement="right"
      width="20%"
      visible={props.visible}
      onClose={props.onClose}
      footer={null}
    >
      <div>
        <Divider orientation="left">
          <Typography.Text strong> Tool : </Typography.Text>
        </Divider>{" "}
        <Dropdown
          options={tools}
          value={props.tool}
          onChange={val => props.setTool(val)}
          className="w-full"
        />
        <Divider orientation="left">
          <Typography.Text strong> Text : </Typography.Text>
        </Divider>
        <div>
          <span
            className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
            onClick={() => {
              if (props.menuObj)
                props.menuObj.addText({
                  text: "TEXT DEMO",
                  fill: textColor,
                  fontFamily,
                  fontSize,
                })
            }}
          >
            Add Text
          </span>

          <span
            className=" block p-1 border border-gray-400 border-solid text-center mt-2 cursor-pointer"
            onClick={() => toggleShowTextColorPicker(!showTextColorPicker)}
          >
            Set text color
          </span>

          {showTextColorPicker && (
            <ColorPicker
              className="w-full"
              type={"GithubPicker"}
              onChange={(value: any) => {
                setTextColor(value.hex)
                if (props.menuObj) props.menuObj.updateText({ fill: value.hex })
              }}
            />
          )}
          <Dropdown
            className="w-full"
            style={{ marginTop: "5px" }}
            options={fontFamilies}
            value={fontFamily}
            onChange={val => {
              setFontFamily(val)
              if (props.menuObj) props.menuObj.updateText({ fontFamily: val })
            }}
          />

          <Dropdown
            options={fontSizes}
            className="w-full"
            style={{ marginTop: "5px" }}
            value={fontSize}
            onChange={val => {
              setFontSize(+val)
              if (props.menuObj) props.menuObj.updateText({ fontSize: +val })
            }}
          />
        </div>
        <Divider orientation="left">
          <Typography.Text strong> Objects : </Typography.Text>
        </Divider>
        <div>
          <span
            onClick={() => toggleShowFillColorPicker(!showFillColorPicker)}
            className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          >
            Set Fill Color
          </span>
          {showFillColorPicker && (
            <ColorPicker
              type={"GithubPicker"}
              onChange={(value: any) => {
                if (props.menuObj) props.menuObj.addFillColor(value.hex)
              }}
            />
          )}

          <span
            onClick={() => toggleShowStrokeColorPicker(!showStrokeColorPicker)}
            className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          >
            Set Stroke Color
          </span>
          {showStrokeColorPicker && (
            <ColorPicker
              type={"GithubPicker"}
              onChange={(value: any) => {
                props._strokeColor(value.hex)
                if (props.menuObj)
                  props.menuObj.updateObjectStyle({ stroke: value.hex })
              }}
            />
          )}

          <Dropdown
            className="w-full"
            style={{ marginTop: "5px" }}
            options={strokeWidths}
            value={props.lineWidth}
            onChange={val => {
              props._lineWidth(val)
              if (props.menuObj)
                props.menuObj.updateObjectStyle({ strokeWidth: val })
            }}
          />
        </div>
        <Divider orientation="left">
          <Typography.Text strong> Image : </Typography.Text>
        </Divider>
        <div>
          <span
            className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
            onClick={() => {
              if (props.menuObj)
                props.menuObj.addImage([
                  "https://static.techspot.com/images2/news/bigimage/2018/09/2018-09-04-image-6.png",
                ])
            }}
          >
            Add Image
          </span>
          <span className=" block p-1  border border-gray-400 border-solid  mt-2 cursor-pointer">
            <input
              type="file"
              accept="images/*"
              onChange={props.menuObj?.uploadImage}
            />
          </span>

          <span
            onClick={() => {
              if (props.menuObj && !props.disabledCropperBtn)
                props.menuObj.openCrop()
            }}
            className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          >
            Crop Image
          </span>
        </div>
        <Divider orientation="left">
          <Typography.Text strong> Extras :</Typography.Text>
        </Divider>
        <div></div>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (props.menuObj) props.menuObj.saveCanvasAsImage("imagefile.png")
          }}
        >
          Save as image
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (props.menuObj) props.menuObj?.removeSelected()
          }}
        >
          Remove Object
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (props.menuObj) props.menuObj?.clearScreen()
          }}
        >
          Clear Screen
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (props.menuObj) props.menuObj.sendBackward()
          }}
        >
          Send back
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (props.menuObj) props.menuObj.sendForward()
          }}
        >
          Bring to front
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (!props.disabledUndo && props.menuObj) props.menuObj?.undo()
          }}
        >
          Undo
        </span>
        <span
          className=" block p-1  border border-gray-400 border-solid text-center mt-2 cursor-pointer"
          onClick={() => {
            if (!props.disabledRedo && props.menuObj) props.menuObj?.redo()
          }}
        >
          Redo
        </span>
      </div>
    </Drawer>
  )
}
export default React.memo(PaintMenu)