import React from 'react'

import { Modal, Form, Input, Select } from "antd";
import moment from "moment";

import { ITaskManagement } from './model';
import { ITask } from '../../../Pages/TaskBoard/model';

const  TasManagementModal = (props : ITaskManagement) => {

    const [form] = Form.useForm();

    React.useEffect(()=>{
        if(props.visible){
            form.setFieldsValue({
                tittle : props.task?.tittle || "",
                state : props.task?.state || "todo",
                description : props.task?.description || "",
                tag : props.task?.tag?.join(",") || "",
                assinged : props.task?.assinged || ""
            })
        }
    },[props.visible])

    const onfinish = () => {
        const formdata = form.getFieldsValue();
        const data : ITask = {
            tittle : formdata?.tittle || "",
            state : formdata?.state || "todo",
            description : formdata?.description || "",
            tag : formdata?.tag?.split(",") || [],
            assinged : formdata?.assinged || "",
            id: props.task?.id || (moment().unix() + formdata?.tittle)
        }
        props.onSubmit(data)
    }

    return (
        <Modal
            title="Manage task"
            visible={props.visible}
            onCancel={props.onClose}
            onOk={ () => form.submit() }
        >
            <Form
                form={form}
                onFinish={onfinish}
                scrollToFirstError
                layout="vertical"
                name="managetask"
            >
                <Form.Item
                    label="Tittle"
                    name="tittle"
                    rules={[
                        {
                          required: true,
                          message: "This field is required!"
                        }
                    ]}
                >
                    <Input placeholder="Task tittle"/>
                </Form.Item>
                <Form.Item
                    label="State"
                    name="state"
                    rules={[
                        {
                          required: true,
                          message: "This field is required!"
                        }
                    ]}
                >   
                    <Select disabled={props.task?.tittle ? true : false}>
                        {
                            ["todo","done","inreview","inprogress"].map(e =>  <Select.Option key={e} value={e}>{e}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Task Description"
                    name="description"
                    rules={[
                        {
                          required: true,
                          message: "This field is required!"
                        }
                    ]}
                >
                    <Input.TextArea placeholder="Task description"/>
                </Form.Item>
                <Form.Item
                    label="Assinged"
                    name="assinged"
                    rules={[
                        {
                          required: true,
                          message: "This field is required!"
                        }
                    ]}
                >   
                    <Select>
                        {
                            ["User 1","User 2","User 3","User 4"].map(e =>  <Select.Option key={e} value={e}>{e}</Select.Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Tags"
                    name="tag"
                >
                    <Input placeholder="Please add comma separated tags."/>
                </Form.Item>
            </Form>
            
        </Modal>
    )
}
export default TasManagementModal