import React from 'react'
import { Tag, Typography } from 'antd'


import { Icon, Icons } from "../../../ui/Icons"
import TasManagementModal from '../../Components/TaskBoardComponent/AddEditTaskModal'

import { DragDrop, Draggable, Droppable } from '../../Components/DragDrop'

import { ITask, ITaskBoard } from './model'

import "./style.css"
import { on } from 'events'

const TaskBoard = (props: ITaskBoard) => {

    const [tasks, _tasks] = React.useState<ITask[]>([
        { tittle: "TIttle 1", description: "Description 1", state: "todo", assinged: "User 1", id: "1-2" },
        { tittle: "Tittle 2", description: "Description 2", state: "todo", assinged: "User 1", id: "1-3" },
        { tittle: "Tittle 3", description: "Description 3", state: "todo", assinged: "User 1", id: "1-4" },
        { tittle: "Tittle 4", description: "Description 4", state: "todo", assinged: "User 1", id: "1-5" },
        { tittle: "Tittle 5", description: "Description 5", state: "todo", assinged: "User 1", id: "1-6" },
    ])
    const [selectedTask, _selectedTask] = React.useState<ITask | any>({})

    const [showManageModal, _showManageModal] = React.useState(false)

    const openManageModal = (t: ITask | undefined) => {
        if (t) {
            _selectedTask(t)
        }
        _showManageModal(true)

    }

    const closeManageModal = () => {
        _selectedTask({})
        _showManageModal(false)
    }

    const onSubmit = (data: ITask) => {
        if (selectedTask.id) {
            const newtaskArr = [...tasks];
            const index = newtaskArr.findIndex(t => t.id === data.id);
            if (index !== -1) {
                newtaskArr[index] = data;

                const todoArr: ITask[] = [...newtaskArr].filter(t => t.state === "todo");
                const inprogressArr: ITask[] = [...newtaskArr].filter(t => t.state === "inprogress");
                const inReviewArr: ITask[] = [...newtaskArr].filter(t => t.state === "inreview");
                const doneArr: ITask[] = [...newtaskArr].filter(t => t.state === "done");

                _tasks([...todoArr, ...inprogressArr, ...inReviewArr, ...doneArr])
            }
        }
        else {
            const todoArr: ITask[] = [...tasks, data].filter(t => t.state === "todo");
            const inprogressArr: ITask[] = [...tasks, data].filter(t => t.state === "inprogress");
            const inReviewArr: ITask[] = [...tasks, data].filter(t => t.state === "inreview");
            const doneArr: ITask[] = [...tasks, data].filter(t => t.state === "done");

            _tasks([...todoArr, ...inprogressArr, ...inReviewArr, ...doneArr])
        }

        closeManageModal()
    }

    const onDragEnd = (e: any) => {
        const taskItem = [...tasks].find(t => t.id === e.draggableId)
        if (taskItem && e.destination?.droppableId) {
            const filterdtaskArr: ITask[] = [...tasks].filter(t => t.id !== e.draggableId);

            const destinationArr: ITask[] = [...filterdtaskArr].filter(t => t.state === e.destination?.droppableId);
            const otherArr: ITask[] = [...filterdtaskArr].filter(t => t.state !== e.destination?.droppableId);

            const data: ITask = { ...taskItem, state: e.destination?.droppableId }
            destinationArr.splice(e.destination?.index, 0, data);

            const todoArr: ITask[] = [...otherArr, ...destinationArr].filter(t => t.state === "todo");
            const inprogressArr: ITask[] = [...otherArr, ...destinationArr].filter(t => t.state === "inprogress");
            const inReviewArr: ITask[] = [...otherArr, ...destinationArr].filter(t => t.state === "inreview");
            const doneArr: ITask[] = [...otherArr, ...destinationArr].filter(t => t.state === "done");
            _tasks([...todoArr, ...inprogressArr, ...inReviewArr, ...doneArr])
        }
    }

    const onDelete = (id: string) => {
        const filterdtaskArr: ITask[] = [...tasks].filter(t => t.id !== id);
        _tasks(filterdtaskArr)
    }

    return (
        <DragDrop onDragEnd={onDragEnd}>
            <div className="flex flex-row w-full text-white">
                <Droppable className="flex flex-col  h-auto w-1/4	 m-1 shadow-inner p-5 taskRow " droppableId="todo">
                    <div className="relative flex  items-center w-full text-center bg-gray-500 p-2 ">
                        <span> To-Do </span>
                        <span className="absolute inline-block ml-2 right-5 cursor-pointer" onClick={() => openManageModal(undefined)}> add new</span>
                    </div>
                    {
                        tasks.filter((t: ITask) => t.state == "todo").map((t: ITask, i: number) => (
                            <Draggable draggableId={t.id} index={i} key={t.id}>
                                <div className="w-full p-2 mt-2 text-black bg-white shadow-xl rounded-md" >
                                    <span className="flex flex-row justify-between">
                                        <Typography.Text strong> Tittle:</Typography.Text>
                                        <div className="grid grid-cols-2">
                                            <span onClick={() => { openManageModal(t) }} className="cursor-pointer">
                                                <Icon icon={Icons.editicon} />
                                            </span>
                                            <span onClick={() => { onDelete(t.id) }} className="cursor-pointer mr-2">
                                                <Icon icon={Icons.delete} />
                                            </span>
                                        </div>
                                    </span>
                                    <Typography.Text className="block w-full" ellipsis> {t.tittle}</Typography.Text>
                                    <Typography.Text strong> Description:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.description}</Typography.Text>
                                    <Typography.Text strong> Assigned:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.assinged}</Typography.Text>
                                    <span >
                                        {
                                            t.tag && t.tag?.length > 0
                                                ? t.tag?.filter(t => t).map(t => <Tag className="w-m-full"> <Typography.Text className="block w-full" ellipsis> {t}</Typography.Text></Tag>)
                                                : null
                                        }
                                    </span>
                                </div>
                            </Draggable>
                        ))
                    }
                </Droppable>
                <Droppable className="flex flex-col  h-auto min-h-1/2 w-1/4 m-1 shadow-inner p-5 taskRow" droppableId="inprogress">
                    <span className="block w-full  bg-gray-500 p-2 "> In Progress </span>
                    {
                        tasks.filter((t: ITask) => t.state == "inprogress").map((t: ITask, i: number) => (
                            <Draggable draggableId={t.id} index={i} key={t.id}>
                                <div className="w-full p-2 mt-2 text-black bg-white shadow-xl rounded-md">
                                    <span className="flex flex-row justify-between">
                                        <Typography.Text strong> Tittle:</Typography.Text>
                                        <div className="grid grid-cols-2">
                                            <span onClick={() => { openManageModal(t) }} className="cursor-pointer">
                                                <Icon icon={Icons.editicon} />
                                            </span>
                                            <span onClick={() => { onDelete(t.id) }} className="cursor-pointer mr-2">
                                                <Icon icon={Icons.delete} />
                                            </span>
                                        </div>
                                    </span>                    <Typography.Text className="block w-full" ellipsis> {t.tittle}</Typography.Text>
                                    <Typography.Text strong> Description:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.description}</Typography.Text>
                                    <Typography.Text strong> Assigned:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.assinged}</Typography.Text>
                                    <span >
                                        {
                                            t.tag && t.tag?.length > 0
                                                ? t.tag?.filter(t => t).map(t => <Tag className="w-m-full"> <Typography.Text className="block w-full" ellipsis> {t}</Typography.Text></Tag>)
                                                : null
                                        }
                                    </span>
                                </div>
                            </Draggable>
                        ))
                    }
                </Droppable>
                <Droppable className="flex flex-col  h-auto min-h-1/2 w-1/4 m-1  shadow-inner p-5 taskRow" droppableId="inreview">
                    <span className="block w-full  bg-gray-500 p-2 "> In Review </span>
                    {
                        tasks.filter((t: ITask) => t.state == "inreview").map((t: ITask, i: number) => (
                            <Draggable draggableId={t.id} index={i} key={t.id}>
                                <div className="w-full p-2 mt-2 text-black bg-white shadow-xl rounded-md">
                                    <span className="flex flex-row justify-between">
                                        <Typography.Text strong> Tittle:</Typography.Text>
                                        <div className="grid grid-cols-2">
                                            <span onClick={() => { openManageModal(t) }} className="cursor-pointer">
                                                <Icon icon={Icons.editicon} />
                                            </span>
                                            <span onClick={() => { onDelete(t.id) }} className="cursor-pointer mr-2">
                                                <Icon icon={Icons.delete} />
                                            </span>
                                        </div>
                                    </span>                                                 <Typography.Text className="block w-full" ellipsis> {t.tittle}</Typography.Text>
                                    <Typography.Text strong> Description:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.description}</Typography.Text>
                                    <Typography.Text strong> Assigned:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.assinged}</Typography.Text>
                                    <span >
                                        {
                                            t.tag && t.tag?.length > 0
                                                ? t.tag?.filter(t => t).map(t => <Tag className="w-m-full"> <Typography.Text className="block w-full" ellipsis> {t}</Typography.Text></Tag>)
                                                : null
                                        }
                                    </span>
                                </div>
                            </Draggable>
                        ))
                    }
                </Droppable>
                <Droppable className="flex flex-col  h-auto min-h-1/2 w-1/4 m-1 shadow-inner p-5 taskRow" droppableId="done">
                    <span className="block w-full  bg-gray-500 p-2 "> Done </span>
                    {
                        tasks.filter((t: ITask) => t.state == "done").map((t: ITask, i: number) => (
                            <Draggable draggableId={t.id} index={i} key={t.id}>
                                <div className="w-full p-2 mt-2 text-black opacity-60 bg-green-300 shadow-xl rounded-md">
                                    <span className="flex flex-row justify-between">
                                        <Typography.Text strong> Tittle:</Typography.Text>
                                        <div className="grid grid-cols-2">
                                            <span onClick={() => { openManageModal(t) }} className="cursor-pointer">
                                                <Icon icon={Icons.editicon} />
                                            </span>
                                            <span onClick={() => { onDelete(t.id) }} className="cursor-pointer mr-2">
                                                <Icon icon={Icons.delete} />
                                            </span>
                                        </div>
                                    </span>                                                  <Typography.Text className="block w-full" ellipsis> {t.tittle}</Typography.Text>
                                    <Typography.Text strong> Description:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.description}</Typography.Text>
                                    <Typography.Text strong> Assigned:</Typography.Text>
                                    <Typography.Text className="block w-full" ellipsis> {t.assinged}</Typography.Text>
                                    <span >
                                        {
                                            t.tag && t.tag?.length > 0
                                                ? t.tag?.filter(t => t).map(t => <Tag className="w-m-full"> <Typography.Text className="block w-full" ellipsis> {t}</Typography.Text></Tag>)
                                                : null
                                        }
                                    </span>
                                </div>
                            </Draggable>
                        ))
                    }
                </Droppable>

                <TasManagementModal
                    visible={showManageModal}
                    onClose={closeManageModal}
                    onSubmit={onSubmit}
                    task={selectedTask}
                />
            </div>
        </DragDrop>
    )
}
export default TaskBoard