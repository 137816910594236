import React from "react"

import PaintCanvas from "./Loadable"

import { IPaintCanvas, IPaintMenuMethods } from "./models"
import PaintMenu from "../../Components/PaintMenu"


const Paint = (props: IPaintCanvas) => {
    const [disabledUndo, setDisabledUndo] = React.useState(true)
    const [disabledRedo, setDisabledRedo] = React.useState(true)
    const [disabledCropperBtn, toggleDisabledCropperBtn] = React.useState(true)
    const [showPaintMenu, _showPaintMenu] = React.useState(false)
    const [menuObj, _menuObj] = React.useState<IPaintMenuMethods | null>(null)
    const [strokeColor, _strokeColor] = React.useState("blue")
    const [lineWidth, _lineWidth] = React.useState(4)

    const [tool, setTool] = React.useState<
        "Pencil" | "Select" | "Circle" | "Rectangle" | "Line"
    >("Pencil")
    const openPaintMenu = () => {
        _showPaintMenu(true)
    }
    const closePaintMenu = () => {
        _showPaintMenu(false)
    }
  const handleObjectSelect = (obj: any) => {
    if (obj.__originalState.type === "image" && disabledCropperBtn) {
      toggleDisabledCropperBtn(false)
    } else {
      toggleDisabledCropperBtn(true)
    }
  }

  const onSketchChange = () => {
    if (menuObj?.canUndo()) {
      setDisabledUndo(false)
    } else {
      setDisabledUndo(true)
    }

    if (menuObj?.canRedo()) {
      setDisabledRedo(false)
    } else {
      setDisabledRedo(true)
    }
  }

  return (
    <div className="flex justify-center">
      <div className="relative h-auto" style={{width: "900px"}}>
      <span className="block absolute top-2 right-2 bg-blue-400 text-white p-1 rounded-md z-10 cursor-pointer" onClick={openPaintMenu}>Paint Menu</span>
      <PaintCanvas
        strokeColor={strokeColor}
        lineWidth={lineWidth}
        backgroundColor="white"
        tool={tool}
        onSelectObject={handleObjectSelect}
        onInit={(menu: IPaintMenuMethods) => _menuObj(menu)}
        onSketchChange={onSketchChange}
        canvasHeight="90vh"
        canvasWidth="100%"
      />
      <PaintMenu 
        visible={showPaintMenu}
        menuObj={menuObj}
        disabledRedo={disabledRedo}
        disabledUndo={disabledUndo}
        tool={tool}
        setTool={setTool}
        disabledCropperBtn={disabledCropperBtn}
        onClose={closePaintMenu}
        _strokeColor={_strokeColor}
        _lineWidth={_lineWidth}
        strokeColor={strokeColor}
        lineWidth={lineWidth}
      />
      </div>
    </div>
  )
}
export default React.memo(Paint)
