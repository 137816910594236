/**
 *
 * @Component DragDrop
 * @Module ui
 */
import React from "react";

// custom imports
import { IDragDropProps } from "./models";
import { DragDropContext } from "react-beautiful-dnd";
import _Draggable from "../Draggable";
import _Droppable from "../Droppable";
// end custom imports

export const DragDrop: React.FC<IDragDropProps> = (props: IDragDropProps) => {
  // PROPERTIES
  return (
    <DragDropContext
      onDragEnd={props.onDragEnd}
      onDragUpdate={props.onDragUpdate}
      onBeforeDragStart={props.onBeforeDragStart}
      onDragStart={props.onDragStart}
    >
      { props.children }
    </DragDropContext>
  );
};
export const Draggable = _Draggable;
export const Droppable = _Droppable;
