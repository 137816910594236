import React from 'react'
import {
    isMobile
} from "react-device-detect";
import { Link } from 'gatsby';

import ProjectModule from "../../components/projectmodule"

import SEO from '../../components/SEO'

export default function index() {
    return (
    <div className="min-h-screen fontMontserrat">
          <SEO title="Projects" />
          {
              isMobile &&
               <div className="fontMontserrat flex justify-center items-center w-full min-h-screen"> 
                    <span className="block bg-gray-600 p-10 text-white text-xl"> 
                        Please visit from web. 
                        <span className="block">
                            <Link to="/" >
                              <span className="text-blue-400" > Click here  </span>   
                            </Link>
                            {" "}to go back to home... 
                        </span>
                    </span> 
               </div>
          }
          {
             !isMobile &&
              <ProjectModule/>
          }

    </div>
    )
}
