import React from "react"
import { Drawer, Typography} from "antd"
import { navigate } from "@reach/router"

import { IMapMenu } from "./model"

const MapMenu = (props: IMapMenu) => {

  const types = ["draw","direction"];

  return (
    <Drawer
      title="Paint Menu"
      placement="right"
      width="20%"
      visible={props.visible}
      onClose={props.onClose}
      footer={null}
    >
        {
            types.map(t => <span
                className="block p-2 text-center bg-blue-400 opacity-60 text-white text-xl mt-2" 
                onClick={()=> {
                    navigate(`/project/mapbox/${t}`);
                    props.onClose()
                }}
            >
                {t}
            </span>)
        }
    </Drawer>
  )
}
export default React.memo(MapMenu)
