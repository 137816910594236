import React from 'react'
import { Select } from 'antd';
import { IDropDown } from './model';

const DropDown = (props: IDropDown) => {
    return (
        <Select
            className={props.className}
            value={props.value}
            placeholder={props.placeholder}
            onSelect={props.onChange}
            style={props.style}
        >
            {
                props.options.map((opt) => ( <Select.Option key={opt.value} value={opt.value}> {opt.display} </Select.Option>) )
            }
        </Select>
    )
}
export default DropDown;