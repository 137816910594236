import React from 'react'
import { Router  } from "@reach/router"

import Menu from "./Menu"

import MapBox from "./Pages/Mapbox"
import TaskBoard from './Pages/TaskBoard'
import Paint from './Pages/Paint'

import "./style.css"

const APP = () => {
    return (
        <div className="flex flex-row w-full fontMontserrat">
            <Menu />
            <Router basepath="/project" className=" w-full ml-60 p-10 h-m-full">
                <MapBox path="/mapbox/*" />
                <TaskBoard path="/taskboard"/>
                <Paint path="/paint"/>

            </Router>
        </div>
    )
}

export default APP;