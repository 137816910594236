/**
 *
 * @Component Draggable
 * @Module ui
 */
import React, { memo } from "react";
import { IDraggableProps } from "./models";
import { Draggable as BeautifulDraggable } from "react-beautiful-dnd";
// end custom imports

const Draggable: React.FC<IDraggableProps> = (props: IDraggableProps) => {
  // PROPERTIES
  const {
    // storied,
    className, style,
  } = props;
  return (
    <BeautifulDraggable
      key={props.draggableId}
      draggableId={props.draggableId}
      index={props.index}
      isDragDisabled={props.isDragDisabled}
      disableInteractiveElementBlocking={props.disableInteractiveElementBlocking}
      // type={props.type}
      shouldRespectForcePress={props.shouldRespectForcePress}
    >
      {
        (provided, snapshot) => (
          <div
            id={props.id}
            className={className}
            style={style}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            { props.childrenWithSnapshot ? props.childrenWithSnapshot(snapshot) : props.children }
          </div>
        )
      }
    </BeautifulDraggable>

  );
};

export default memo(Draggable);
