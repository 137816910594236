import React from 'react'
import { Router } from '@reach/router';
import { IPageProps } from '../../model';

import Map from "./Loadable"

import { DirectionUnitType, IDirectionProperties, LogoPosition, MapStyle, DirectionType, IDrawControl, DrawProperties } from './models';
import MapMenu from '../../Components/MapboxExampleSelection';

import "./style.css"


const MapBox = (props: IPageProps) => {
    const [showManpMenu, _showMapMenu] = React.useState(false);

    const openMapMenu = () => {
        _showMapMenu(true)
    }

    const closeMapMenu = () => {
        _showMapMenu(false)
    }

    const [locations] = React.useState({
        startlocaton: "Delhi, India",
        endlocation: "Kolkata, WestBengal, India",
      });

    const directionProperties: IDirectionProperties = {
        position: "top-right" as LogoPosition,
        unit: "metric" as DirectionUnitType,
        type: "driving" as DirectionType,
        interactive: true,
        locations,
      };
    const drawerProperties: DrawProperties = {
        position: "bottom-left",
        controls: {
            point: true,
            line_string: true,
            polygon: true,
            trash: true,
            combine_features: true,
            uncombine_features: true
        },
        displayControlsDefault: true
    }   

    return (
        <div className="flex justify-center items-center flex-col w-full" >
            <div style={{width: "800px"}}>
                <span className="block bg-blue-400 p-1 w-full text-white mb-2 text-center cursor-pointer" onClick={openMapMenu}> Examples </span>
                <Router className="h-m-full"  >
                    <Map path="/" containerStyle={{width: "100%", height:"900px"}}/>
                    <Map 
                        path="/draw" 
                        mapstyle={"streets" as MapStyle} isDraw={true} 
                        containerStyle={{width: "100%", height:"900px"}}
                        style={{position: "relative"}}
                        drawerProperties = {drawerProperties }
                    />
                    <Map path="/direction"    
                        mapstyle={"streets" as MapStyle}
                        isDirection={true}
                        currentLocation={[-84.518641, 39.13427]}
                        directionProperties={directionProperties}
                        zoom={[5]}
                        center={[-75.789, 41.874]}
                        containerStyle={{width: "100%", height:"900px"}}
                        style={{position: "relative"}}
                    />
                </Router>
            </div>
            <MapMenu 
                visible={showManpMenu}
                onClose={closeMapMenu}
            />
        </div>
    )
}

export default MapBox;