/**
 *
 * @Component Droppable
 * @Module ui
 */
import React, { memo } from "react";

// custom imports
import { IDroppableProps } from "./models";
import { Droppable as BeautifulDroppable } from "react-beautiful-dnd";
// end custom imports

const Droppable: React.FC<IDroppableProps> = (props: IDroppableProps) => {
  // PROPERTIES
  const {
    // storied,
    className, style,
  } = props;
  return (
    <BeautifulDroppable
      droppableId={props.droppableId}
      type={props.type}
      isDropDisabled={props.isDropDisabled}
      isCombineEnabled={props.isCombineEnabled}
      direction={props.direction}
      ignoreContainerClipping={props.ignoreContainerClipping}
    >
      {
        (provided, snapshot) => (
          <div id={props.id}
            className={className}
            style={ style }
            ref={provided.innerRef}
            {...provided.droppableProps}
            >
            { props.childrenWithSnapshot ? props.childrenWithSnapshot(snapshot) : props.children }
            {provided.placeholder}
          </div>
        )
      }
    </BeautifulDroppable>
  );
};

export default memo(Droppable);
