/**
 *
 * @Component ColorPicker
 * @Module ui
 */
import React, { memo } from "react";

// custom imports
import { IColorPickerProps } from "./models";
import * as ColorPickerChoice from "react-color";
// end custom imports

const ColorPicker: React.FC<IColorPickerProps> = (props: IColorPickerProps) => {
  // PROPERTIES
  const { className, style, disableAlpha } = props;
  const type = props.type || "ChromePicker";
  const Picker = (ColorPickerChoice as any)[type];
  return (
    <Picker
      id={props.id}
      className={className}
      style={style}
      onChange={props.onChange}
      onChangeComplete={props.onChangeComplete}
      color={props.color}
      disableAlpha={disableAlpha}
    />
  );
};

export default memo(ColorPicker);
