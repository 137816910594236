import React from 'react'
import { Menu, Button, Row, Col } from 'antd';
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

import { Icons, Icon } from "../../ui/Icons"

import "./style.css"

const { SubMenu } = Menu;


const ProjectMenu = () => {

    const [ activeKey, _activeKey ]  = React.useState("/project/mapbox")

    const location = useLocation();
    
    React.useEffect(()=>{

        _activeKey(location.pathname)

    }, [location])
    return (
            <div className="w-60 fixed bg-white Z-2">
            <Menu
                mode="inline"
                className="projectmenu"
                activeKey={activeKey}
            >
                <Menu.Item key="home" className="laytout-submenu bg-gray-500 opacity-40 text-white back-home" icon={<Icon icon={Icons.home} className="mr-2"/>} onClick={()=> navigate("/")}>
                        BACK TO HOME
                </Menu.Item>
                <SubMenu title="Maps" icon={<Icon icon={Icons.map} className="mr-2" />} >
                    <Menu.Item key="/project/mapbox" className="laytout-submenu" icon={<Icon icon={Icons.mapbox} className="mr-2"/>} onClick={()=> navigate("/project/mapbox")}>
                        Mapbox
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="/project/paint"  className="laytout-submenu" icon={<Icon icon={Icons.paint} className="mr-2"/>} onClick={()=> navigate("/project/paint")}>
                        Paint
                </Menu.Item>
                <Menu.Item key="/project/taskboard" className="laytout-submenu" icon={<Icon icon={Icons.taskboard} className="mr-2" />} onClick={()=> navigate("/project/taskboard")}>
                        Task Board
                </Menu.Item>
                {/* <Menu.Item key="/project/modelbuilder" className="laytout-submenu" icon={<Icon icon={Icons.json} className="mr-2" />} onClick={()=> navigate("/project/modelbuilder")}>
                        JSON Builder
                </Menu.Item>
                <SubMenu title="Google"  icon={<Icon icon={Icons.google}  className="mr-2"/>}>
                    <Menu.Item key="/project/googledrive" className="laytout-submenu" icon={<Icon icon={Icons.home} className="mr-2"/>} onClick={()=> navigate("/project/googledrive")}>
                        Goodle Drive Link
                    </Menu.Item>
                </SubMenu>
                <Menu.Item key="/project/chatbot"  icon={<Icon icon={Icons.chatbot} />} className="laytout-submenu" onClick={()=> navigate("/project/chatbot")}>
                    Chat Bot
                </Menu.Item> */}
            </Menu>
            </div>
    )
}

export default ProjectMenu;